export default function Descripcion(props){

    if(props.desc){

        return(

            <div className="producto_footer_desc">{props.desc}</div>
            
        )

    }    

}
import Producto from "./producto";

export default function Productos(props) {
  const urlQuery = new URLSearchParams(window.location.search);
  const queryStrings = { price: urlQuery.get("p"), stock: urlQuery.get("s") };
  document.title += " " + queryStrings.price;
  return (
    <div className="container_principal">
      <div className="productos_header">
        <h1>Catálogo {queryStrings.price}</h1>
      </div>
      <div className="productos_grid">
        {props.list.map((element, i) =>
          element.activo === "1" ? (
            <Producto producto={element} queryParams={queryStrings} key={i} />
          ) : null
        )}
      </div>
    </div>
  );
}

import Precio from "./producto_precio";
import Descripcion from "./producto_desc";
import Variacion from "./producto_variacion";
import Imagen from "./producto_imagen";
import MiniLogo from "../logo100x100.webp";
import Etiqueta from "./producto_etiqueta";
import Stock from "./producto_stock";

export default function Producto(props) {
  return (
    <div className="producto_card">
      <div className="producto_header">
        <div className="producto_header_nombre">
          <h3>{props.producto.nombre}</h3>
          <span>Ref. {props.producto.referencia}</span>
        </div>
        <Imagen
          imagen={MiniLogo}
          alt="Alma Kids"
          clase="producto_header_logo"
        />
      </div>
      <div className="producto_img_container">
        <Imagen
          imagen={props.producto.imagenes}
          alt={"Referencia " + props.referencia}
          clase="producto_img"
        />
        <Variacion stock={props.producto.stock} />
        <Etiqueta
          precios={props.producto.precio.find(
            (e) => e.nombre === props.queryParams.price
          )}
        />
        <Stock
          inventario={props.producto.stock}
          queryStock={props.queryParams.stock}
        />
      </div>
      <div className="producto_footer">
        <Descripcion desc={props.producto.descripcion} />
        <Precio
          precios={props.producto.precio.find(
            (e) => e.nombre === props.queryParams.price
          )}
        />
      </div>
    </div>
  );
}

export default function Imagen(props) {
  return (
    <div className={props.clase}>
      <img
        loading="lazy"
        width="100%"
        height="auto"
        src={props.imagen}
        alt={props.alt}
      />
    </div>
  );
}

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import imgLoader from "./logo700x700.webp";
import Loader from "./componentes/loader";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
const logoLoader = new Image();
logoLoader.src = imgLoader;
ReactDOM.createRoot(document.getElementById("loader")).render(
  <Loader logoLoader={logoLoader} />
);
get_csv(
  "https://docs.google.com/spreadsheets/d/1_Ai58ltfM--oy29A3ydFydFAR4mp8lk4XubywzwXmvY/gviz/tq?tqx=out:csv&gid=1932441262"
);

function get_csv(link) {
  fetch(link)
    .then((response) => response.text())
    .then((text) => csvToArray(text));
}
function csvToArray(csvStr) {
  const headers = csvStr
    .slice(0, csvStr.indexOf("\n"))
    .replaceAll('"', "")
    .toLowerCase()
    .split(",");
  const filas = csvStr.slice(csvStr.indexOf("\n") + 1).split("\n");
  const skus = [];

  /*headers.map(
    (header, i) => (headers[i] = header.replaceAll('"', "").toLowerCase())
  );*/
  filas.map((fila, i) => (filas[i] = fila.split('","')));
  filas.map((fila, i) =>
    fila.map((element, e) => (fila[e] = element.replaceAll('"', "")))
  );

  filas.map((fila, i) => (skus[i] = fila[headers.indexOf("referencia")]));

  const skusUnicos = [...new Set(skus)];
  const listProducts = [];

  filas.forEach((fila) => {
    let i = skusUnicos.indexOf(fila[headers.indexOf("referencia")]);

    if (typeof listProducts[i] !== "object") {
      listProducts[i] = {};
    }

    headers.forEach((header, e) => {
      if (
        header.startsWith("atributo 1") &&
        fila[headers.indexOf("activo")] === "1"
      ) {
        //let atributo = header.replace("atributo:","").trim();

        if (!listProducts[i]["stock"]) {
          listProducts[i]["stock"] = [];
        }

        listProducts[i]["stock"][listProducts[i]["stock"].length] = {
          atributo1: fila[e],
          hex: fila[headers.indexOf("hex")],
          "04": fila[headers.indexOf("atributo 2: 04")],
          "06": fila[headers.indexOf("atributo 2: 06")],
          "08": fila[headers.indexOf("atributo 2: 08")],
          "10": fila[headers.indexOf("atributo 2: 10")],
          "12": fila[headers.indexOf("atributo 2: 12")],
          "14": fila[headers.indexOf("atributo 2: 14")],
          "16": fila[headers.indexOf("atributo 2: 16")]
        };
      } else if (
        header.startsWith("precio") &&
        fila[headers.indexOf("activo")] === "1"
      ) {
        if (!listProducts[i]["precio"]) {
          listProducts[i]["precio"] = [];
        }

        if (header.startsWith("precio nombre")) {
          if (listProducts[i]["precio"].length < 4 /* maximo de precios */) {
            listProducts[i]["precio"][listProducts[i]["precio"].length] = {
              nombre: fila[e],
              descuento:
                fila[
                  headers.indexOf(
                    header.replace("precio nombre", "precio descuento")
                  )
                ],
              etiqueta:
                fila[
                  headers.indexOf(
                    header.replace("precio nombre", "precio etiqueta")
                  )
                ],
              precio:
                fila[
                  headers.indexOf(
                    header.replace("precio nombre", "precio valor")
                  )
                ],
              texto:
                fila[
                  headers.indexOf(
                    header.replace("precio nombre", "precio texto")
                  )
                ]
            };
          }
        }
      } else if (!header.startsWith("talla") && !header.startsWith("precio")) {
        listProducts[i][header] = fila[e].replaceAll(";", "\n");
      }
    });
  });
  console.log(listProducts);
  root.render(
    <React.StrictMode>
      <App productos={listProducts} />
    </React.StrictMode>
  );
}
window.addEventListener("load", async function (event) {});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

export default function Precio(props) {
  if (props.precios) {
    const precioParseInt = parseInt(props.precios.precio, 10);
    const precio = props.precios.descuento
      ? Math.round(precioParseInt * (1 - props.precios.descuento / 100))
      : precioParseInt;
    return (
      <div className="producto_footer_precio">
        {precioParseInt !== precio ? (
          <span className="producto_footer_precio_descuento">
            {precioParseInt.toLocaleString("es-CO")}
          </span>
        ) : (
          ""
        )}
        <h4 className="producto_footer_precio_valor">
          {precio.toLocaleString("es-CO")}
          <span className="producto_footer_precio_currency">COP</span>
        </h4>
        <span className="producto_footer_precio_texto">
          {props.precios.texto}
        </span>
      </div>
    );
  }
}

export default function Stock(props) {
  if (props.inventario && props.queryStock !== null) {
    return (
      <div className="producto_stock">
        <div className="producto_stock_open">
          <span
            onClick={(e) =>
              e.target.parentElement.parentElement.classList.toggle("opened")
            }
          >
            Disponibilidad
          </span>
        </div>

        <table>
          <thead>
            <tr>
              <td>Color / Talla</td>
              <td>04</td>
              <td>06</td>
              <td>08</td>
              <td>10</td>
              <td>12</td>
              <td>14</td>
              <td>16</td>
            </tr>
          </thead>
          <tbody>
            {props.inventario.map((element, i) => (
              <tr key={i}>
                <td>{element.atributo1}</td>

                {element["04"] > 0 ? (
                  <td>{props.queryStock === "all" ? element["04"] : "✓"}</td>
                ) : (
                  <td className="out_stock">
                    {props.queryStock === "all" ? element["04"] : "×"}
                  </td>
                )}
                {element["06"] > 0 ? (
                  <td>{props.queryStock === "all" ? element["06"] : "✓"}</td>
                ) : (
                  <td className="out_stock">
                    {props.queryStock === "all" ? element["06"] : "×"}
                  </td>
                )}
                {element["08"] > 0 ? (
                  <td>{props.queryStock === "all" ? element["08"] : "✓"}</td>
                ) : (
                  <td className="out_stock">
                    {props.queryStock === "all" ? element["08"] : "×"}
                  </td>
                )}
                {element["10"] > 0 ? (
                  <td>{props.queryStock === "all" ? element["10"] : "✓"}</td>
                ) : (
                  <td className="out_stock">
                    {props.queryStock === "all" ? element["10"] : "×"}
                  </td>
                )}
                {element["12"] > 0 ? (
                  <td>{props.queryStock === "all" ? element["12"] : "✓"}</td>
                ) : (
                  <td className="out_stock">
                    {props.queryStock === "all" ? element["12"] : "×"}
                  </td>
                )}
                {element["14"] > 0 ? (
                  <td>{props.queryStock === "all" ? element["14"] : "✓"}</td>
                ) : (
                  <td className="out_stock">
                    {props.queryStock === "all" ? element["14"] : "×"}
                  </td>
                )}
                {element["16"] > 0 ? (
                  <td>{props.queryStock === "all" ? element["16"] : "✓"}</td>
                ) : (
                  <td className="out_stock">
                    {props.queryStock === "all" ? element["16"] : "×"}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

import "./App.css";
import Productos from "./componentes/productos";

function App(props) {
  //document.querySelector(".loader_loading").style.display = "none";
  setTimeout(() => {
    document.querySelector("#loader").style.left = "-100%";
    document.querySelector("body").style.overflow = "auto";
  }, 1000);

  return <Productos list={props.productos} />;
}

export default App;

export default function Variacion(props) {
  return (
    <div className="producto_variacion">
      {props.stock.map((element, i) =>
        element.atributo1 !== "" ? (
          <div className="producto_variacion_atributo" key={i}>
            <span
              className="producto_variacion_atributo_color"
              style={{ background: element.hex }}
            ></span>
            <span>{element.atributo1}</span>
          </div>
        ) : null
      )}
    </div>
  );
}

export default function Etiqueta(props) {
  if (props.precios && props.precios.etiqueta) {
    return (
      <div className="producto_etiqueta_container">
        <span className="producto_etiqueta_container_valor">
          {props.precios.etiqueta}
        </span>
      </div>
    );
  }
}

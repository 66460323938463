import Imagen from "./producto_imagen";

export default function Loader(props) {
  return (
    <div className="loader_container">
      <Imagen
        imagen={props.logoLoader.src}
        referencia={"Loader"}
        clase="loader_logo"
      />
      <div className="loader_loading">
        <span className="loader_loading_circle"></span>Cargando...
      </div>
    </div>
  );
}
